html {
    scroll-behavior: smooth;
}

:root {
    --h: 100vh;
}

body {
    overflow: overlay;
}

::-webkit-scrollbar {
    width: 10px;
}
  
::-webkit-scrollbar-track {
    background: transparent;
}
  
::-webkit-scrollbar-thumb {
    background: rgba(80, 80, 80, 0);
    border-radius: 10px;
}
body:hover::-webkit-scrollbar-thumb {
    background: rgba(80, 80, 80, 0.5);
}
::-webkit-scrollbar-thumb:hover {
    background: rgba(50, 50, 50, 0.5);
}
::-webkit-scrollbar-thumb:active {
    background: rgba(20, 20, 20, 0.5);
}


h1 {
    text-align: center;
    font-weight: 500;
    font-size: 80px;
    margin: 20px;
}

.logo-main {
    margin: 20px;
    width: 400px;
}
.frodoPreview {
    width: 75%;
}
.ppContainer {
    width: 35vmin;
    height: 35vmin;
}
.pp {
    clip-path: circle(calc(100% / 2));
    width: 20vmin;
    height: 20vmin;
    position: absolute;
}

.leaderboardList {
    padding-left: 500px;
    padding-right: 500px;
}
@media only screen and (max-width: 1700px) {
    .leaderboardList {
        padding-left: 450px;
        padding-right: 450px;
    }
}
@media only screen and (max-width: 1500px) {
    .leaderboardList {
        padding-left: 300px;
        padding-right: 300px;
    }
}
@media only screen and (max-width: 959px) {
    .frodoPreview {
        width: 300px;
    }
    .ppContainer {
        margin-left: 50%;
        transform: translateX(-50%);
        width: 60vmin;
        height: 60vmin;
    }
    .pp {
        width: 35vmin;
        height: 35vmin;
    }
    .leaderboardList {
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media only screen and (max-width: 600px) {
    .logo-main {
        width: 70vw;
    }
    .frodoPreview {
        width: 70vw;
    }
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.announmentBody {
    height: 100vh;
    overflow: hidden;
    background-attachment: fixed;
    background-image: linear-gradient(to top, #209cff 0%, #68e0cf 100%);
}

section {
    height: 100vh;
    overflow: hidden;
    // width: 100vw;
    background-attachment: fixed;
}
section .part {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
}
section:nth-of-type(even) {
    z-index: 9;
    background: white;
}
section:nth-of-type(1) {
    z-index: -10;
    background-image: linear-gradient(to top, #209cff 0%, #68e0cf 100%);
}
section:nth-of-type(2) {
    margin-top: 30px;
}
section:nth-of-type(3) {
    z-index: 9;
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}


@media only screen and (max-width: 959px) {
    section {
        overflow: visible;
        min-height: 100vh;
        height: unset;
    }
    section .part {
        display: block;
        min-height: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 30px;
        margin-right: -9px;
    }
}

.command {
    overflow-y: hidden;
    background-attachment: fixed;
    padding: 30px;
}
.command.command-0 {
    background-image: linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%);
}
.command.command-1 {
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
}
.command.command-2 {
    background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
}
.command.command-3 {
    background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
}
.command.command-4 {
    background-image: radial-gradient(circle 248px at center, #16d9e3 0%, #30c7ec 47%, #46aef7 100%);
}
.command.command-5 {
    background-image: linear-gradient(to top, #fddb92 0%, #d1fdff 100%);
}
.command.command-6 {
    background-image: linear-gradient(to top, #96fbc4 0%, #f9f586 100%);
}
.command.command-7 {
    background-image: linear-gradient(to top, #a3bded 0%, #6991c7 100%);
}
.command.command-8 {
    background-image: linear-gradient(to top, #7028e4 0%, #e5b2ca 100%);
}
.command.command-9 {
    background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
}
.command.command-10 {
    background-image: linear-gradient(-20deg, #b721ff 0%, #21d4fd 100%);
}
.command.command-11 {
    background-image: linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%);
}

.commandPicture {
    width: 100%;
    border-radius: 15px;
}

.frodoStats {
    text-align: center;
}

nav a {
    color: inherit;
    text-decoration: inherit;
}

a {
    text-decoration: none;
}

@keyframes arrowAnim {
    0% {
        bottom: 20px;
    }
    50% {
        bottom: 30px;
    }
    100% {
        bottom: 20px;
    }
}
.arrowAnimated {
    animation: arrowAnim 1.3s linear infinite;
	position: absolute;
	bottom: 30;
	left: 50%;
	transform: translateX(-50%);
	transition: bottom 0.3s ease-in;
	cursor: pointer;
}

.contents {
    width: 100%;
    max-width: 360px;
    background: rgba(50, 50, 50, 0.2);
    border: black 1px solid;
    border-radius: 10px;
}
.commandSyntax {
    background: rgba(211, 211, 211, 0.5);
    padding: 10px;
    border: black 1px solid;
    border-radius: 5px;
    width: 100%;
	color: black;
}

.commandPopup {
    padding: 20px;
}
.commandsCode {
    background: rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    font-size: small;
    margin-top: 5px;
}

.leaderboardPfp {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 20px;
    display: inline;
}
.username {
    display: inline;
    padding-top: -10px;
}
.user {
    text-align: center;
}

.inviteBtn {
	margin-right: 10px;
	margin-left: auto;
}

.feedback-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: all 0.5s linear;
	opacity: 0;
	pointer-events: none;
}
.form {
	transition: all 0.5s linear;
	position: relative;
}
.form-control {
	margin: 10px;
}

.wave {
	position: absolute;
	bottom: 0px;
	z-index: -10;
	background-position: center bottom;
	width: 100%;
	height: 100%;
	background-repeat: repeat-x;
}

.hidden {
	display: none;
}

.command-description {
	margin: 10px;
}

.profile-name {
	color: blue;
	cursor: pointer;
}

.pfpDialog {
	margin: auto;
}
.socials {
	display: flex;
	justify-content: center;
	align-items: center;
}
.social {
	width: 30px;
	pointer-events: none;
	padding: 3px;
}
.copy-text {
	cursor: pointer;
}

.new-quiz-field {
	margin-top: 5px !important;
}

.image-upload-container {
	width: 100%;
	height: 100%;
	margin-top: 5px;
}

.reset-upload-button {
	position: absolute;
	top: 5px;
	right: 0px;
	z-index: 30;
}

.upload-image {
	position: relative;
	max-height: min-content;
	img {
		display: inline-flex;
		width: 100%;
		max-height: 150px;
		margin-right: 10px;
		object-fit: contain;
		border-radius: 5px;
	}
}

.quiz-bubble {
	border: #201adb25 1px solid;
	border-radius: 5px;
	padding: 10px;
	margin: 5px;
}
.draggable-question {
	border: #201adb25 1px solid;
	border-radius: 5px;
	padding: 10px;
	margin: 5px;
	width: 100%;
	display: inline-block;
	background:rgba(0, 0, 0, 0.1);
}
.question-drag-icon {
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translateY(-50%);
}

.questions {
	padding: 10px;
	margin: 15px;
}
